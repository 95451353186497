import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthQuery, UserProfileApiService } from '@ct/auth';
import { DialogService, SpinnerService, TabHeader } from '@ct/components';
import {
  BlogPost,
  ChannelDocumentUploadApiService,
  ChannelVideoUploadApiService,
  DOCUMENT_MIME_TYPES,
  DocumentUpload,
  EnrichedVideoUploadEntity,
  GoogleCalendarEventItem,
  ParseLinktreeResponse,
  TranscodingState,
  UserProfile,
  VideoChannelEntity,
  VideoOrientation,
  VideoUploadEntity
} from '@ct/core';
import {
  BLOG_POSTS_FEATURE_LIMIT,
  BlogPostApiService,
  ChannelEditDialogComponent,
  ChannelEditLinksDialogComponent,
  ChannelImportLinksDialogComponent,
  entitySlugUrl,
  LikeApiService,
  PostsQuery,
  trackById
} from '@ct/shared';
import { Observable, of, Subject } from 'rxjs';
import { finalize, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';

export const CHANNEL_FEATURE_LIMIT = 10;

export enum ChannelDetailsTab {
  Activity = 'activity',
  Stories = 'stories',
  Media = 'media',
  Events = 'events',
  Documents = 'documents',
  Links = 'links'
}

interface ChannelQueryParams {
  offset: number;
  filter: ChannelDetailsTab;
}

const DEFAULT_OFFSET = 0;
const DEFAULT_FILTER = ChannelDetailsTab.Activity;

@Component({
  selector: 'ct-user-channel',
  templateUrl: './user-channel.component.html',
  styleUrls: ['./user-channel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserChannelComponent implements OnDestroy {
  protected files: VideoUploadEntity[] = [];
  protected selectedVideoName: string;
  protected selectedFiles: any[];
  protected channelStatus = ChannelDetailsTab;
  public posts: BlogPost[] = [];
  public calendarEvents: GoogleCalendarEventItem[] = [];
  public documents: DocumentUpload[] = [];
  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  protected activeCommentsEntityId: string | null;
  protected readonly trackByFn = trackById;
  protected channel: VideoChannelEntity;
  protected readonly transcodingState = TranscodingState;
  protected readonly documentMimeTypes = DOCUMENT_MIME_TYPES.join(',');

  get canEdit(): boolean {
    return this.channel && !!this.channel?.adminIds?.includes(this.authQuery.profile?.userId as string);
  }

  get canJoin(): boolean {
    return (
      !this.canEdit &&
      this.channel &&
      this.authQuery.profile != null &&
      !(this.authQuery.profile.videoChannelIds ?? []).includes(this.channel?.id as string)
    );
  }

  get canLeave(): boolean {
    return (
      this.channel &&
      this.authQuery.profile != null &&
      (this.authQuery.profile.videoChannelIds ?? []).includes(this.channel?.id as string)
    );
  }

  public queryParams: ChannelQueryParams = {
    offset: DEFAULT_OFFSET,
    filter: DEFAULT_FILTER
  };

  public tabs: TabHeader[] = [
    { name: ChannelDetailsTab.Activity, labelKey: 'Timeline', selected: true },
    { name: ChannelDetailsTab.Stories, labelKey: 'Stories' },
    { name: ChannelDetailsTab.Media, labelKey: 'Media' },
    { name: ChannelDetailsTab.Events, labelKey: 'Events' },
    { name: ChannelDetailsTab.Documents, labelKey: 'Documents' },
    {
      name: ChannelDetailsTab.Links,
      labelKey: 'Links',
      shouldShow: () => Boolean(this.channel?.links?.length) || this.canEdit
    }
  ];

  protected get m3u8() {
    return this.selectedFiles?.find((file) => file.fileName === 'manifest.m3u8')?.publicUrl;
  }

  protected get mpd() {
    return this.selectedFiles?.find((file) => file.fileName === 'manifest.mpd')?.publicUrl;
  }

  protected readonly trackById = trackById;

  protected loading = false;
  protected showLoadButton = true;

  private destroyed$ = new Subject();
  private readonly limit = CHANNEL_FEATURE_LIMIT;

  constructor(
    private spinnerService: SpinnerService,
    private videoUploadApiService: ChannelVideoUploadApiService,
    private documentUploadApiService: ChannelDocumentUploadApiService,
    private userProfileApiService: UserProfileApiService,
    private blogPostApiService: BlogPostApiService,
    private likeApiService: LikeApiService,
    private postsQuery: PostsQuery,
    private authQuery: AuthQuery,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.route.data
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(({ channel }) => {
          this.files = channel;
          return this.route.params;
        }),
        switchMap(({ channelName }) => {
          return this.getChannelInfo(channelName ?? this.authQuery.profile?.userId);
        })
      )
      .subscribe(() => {
        if (this.files.length) {
          this.onPlayVideo(this.files[0]);
        }
      });

    this.postsQuery.selectPosts$.pipe(takeUntil(this.destroyed$)).subscribe((posts) => {
      this.refreshPosts(posts);
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  toggleComments(entity: any) {
    this.selectedVideoName === entity.fileName
      ? (this.selectedVideoName = '')
      : (this.selectedVideoName = entity?.fileName ?? '');
    // this.activeCommentsEntityId === entity.id
    //   ? (this.activeCommentsEntityId = null)
    //   : (this.activeCommentsEntityId = entity?.id ?? null);
    this.changeDetectorRef.markForCheck();
  }

  onFilterChanged({ name }: TabHeader) {
    if (name === this.queryParams.filter) {
      return;
    }
    this.queryParams = {
      ...this.queryParams,
      offset: DEFAULT_OFFSET,
      filter: name as ChannelDetailsTab
    };
    this.tabs = this.tabs.map((tab) => ({
      ...tab,
      selected: tab.name === name
    }));

    switch (this.queryParams.filter) {
      case ChannelDetailsTab.Stories:
        this.selectedVideoName = '';
        this.loadPosts(this.queryParams);
        break;
      case ChannelDetailsTab.Activity:
        this.selectedVideoName = '';
        this.loadMedia(this.queryParams, true);
        break;
      case ChannelDetailsTab.Media:
        this.selectedVideoName = '';
        this.loadMedia(this.queryParams);
        break;
      case ChannelDetailsTab.Events:
        this.selectedVideoName = '';
        this.loadCalendarEvents(this.queryParams);
        break;
      case ChannelDetailsTab.Documents:
        this.selectedVideoName = '';
        this.loadDocuments(this.queryParams);
        break;
    }
  }

  onSidebarShowStories() {
    this.onFilterChanged({ name: 'stories', labelKey: 'stories' });
    try {
      if (this.document?.scrollingElement) {
        this.document.scrollingElement.scrollTop = 0;
      }
    } catch (err) {
      // silently catch if the view isn't fully rendered yet
    }
  }

  onLike(entity: EnrichedVideoUploadEntity) {
    this.likeApiService
      .like({
        entityId: entity.metadataId as string,
        entityType: entity.type
      })
      .subscribe((likes) => {
        entity.metadata = {
          ...(entity.metadata ?? {}),
          likes
        };
        this.changeDetectorRef.markForCheck();
      });
  }

  onDislike(entity: any) {
    this.likeApiService
      .dislike({
        entityId: entity.metadataId as string,
        userId: this.authQuery.profile?.userId as string
      })
      .subscribe((likes) => {
        entity.metadata = {
          ...(entity.metadata ?? {}),
          likes
        };
        this.changeDetectorRef.markForCheck();
      });
  }

  onScroll() {
    if (this.loading || !this.showLoadButton) {
      return false;
    }
    this.loading = true;
    this.queryParams.offset = this.queryParams.offset + this.limit;

    switch (this.queryParams.filter) {
      case ChannelDetailsTab.Stories:
        this.loadPosts(this.queryParams);
        break;
      case ChannelDetailsTab.Activity:
        return false;
      case ChannelDetailsTab.Media:
        this.loadMedia(this.queryParams);
        break;
      case ChannelDetailsTab.Events:
        this.loadCalendarEvents(this.queryParams);
        break;
    }
  }

  async onSelectFiles(files: File[]) {
    this.spinnerService.show({ instant: true });
    let orientation = VideoOrientation.Landscape;
    let width = 0;
    let height = 0;
    const duration = await this.onGetDuration(files[0]);
    try {
      const result = (await this.onGetOrientation(files[0])) ?? { width, height, orientation };
      orientation = result.orientation;
      width = result.width;
      height = result.height;
    } catch (e) {
      console.warn(e);
    }
    this.videoUploadApiService
      .getUploadLink({ orientation, duration, width, height, fileName: files[0].name })
      .pipe(
        switchMap(({ uploadLink }) => {
          return this.videoUploadApiService.useUploadLink(files[0], uploadLink);
        }),
        finalize(() => {
          this.spinnerService.hide();
          this.queryParams = {
            ...this.queryParams,
            offset: DEFAULT_OFFSET,
            filter: ChannelDetailsTab.Media
          };

          this.tabs = this.tabs.map((tab) => ({
            ...tab,
            selected: tab.name === ChannelDetailsTab.Media
          }));

          this.selectedVideoName = '';
          this.loadMedia(this.queryParams);
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe();
  }

  async onSelectDocuments(files: File[]) {
    this.spinnerService.show({ instant: true });

    const file = files[0];

    const { name: filename, type: mimetype, size } = file;

    this.documentUploadApiService
      .getUploadLink({
        mimetype,
        size,
        filename,
        originalname: filename,
        relatedChannelIds: this.channel.id ? [this.channel.id] : []
      })
      .pipe(
        switchMap(({ uploadLink }) => {
          return this.documentUploadApiService.useUploadLink(files[0], uploadLink);
        }),
        finalize(() => {
          this.spinnerService.hide();
          this.queryParams = {
            ...this.queryParams,
            offset: DEFAULT_OFFSET,
            filter: ChannelDetailsTab.Documents
          };

          this.tabs = this.tabs.map((tab) => ({
            ...tab,
            selected: tab.name === ChannelDetailsTab.Documents
          }));

          this.loadDocuments(this.queryParams);
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe();
  }

  onPlayVideo(video: { fileName: string; name: string }) {
    this.spinnerService.show({ instant: true });
    this.videoUploadApiService
      .getFromChannelByFilename(this.route.snapshot.params.channelName ?? this.channel.handle, video.name)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe((file) => {
        this.selectedFiles = file;
        this.selectedVideoName = video.fileName;
      });
    // launch Video.js player using m3u playlist from hls
  }

  decodeUrl(url: string): string {
    return decodeURIComponent(url);
  }

  getLink(post: BlogPost) {
    return entitySlugUrl('/stories/', post);
  }

  refreshCalendarEvents(events: GoogleCalendarEventItem[]) {
    if (this.queryParams.offset === 0) {
      this.calendarEvents = [...(events ?? [])];
    } else {
      this.calendarEvents.push(...(events ?? []));
    }
    this.showLoadButton = !(events.length === 0 || events.length < this.limit);
    this.loading = false;
    this.changeDetectorRef.markForCheck();
  }

  loadCalendarEvents({ offset }: ChannelQueryParams) {
    if (this.channel.googleCalendarPublicUrl) {
      this.loading = true;
      this.spinnerService.show({ instant: true });
      this.changeDetectorRef.markForCheck();

      this.videoUploadApiService
        .getGoogleCalendarEvents(this.channel.googleCalendarPublicUrl)
        .pipe(
          map((events) => {
            return events
              .filter(
                (event) =>
                  new Date(event.start.dateTime ?? (event.start.date as string)).getTime() > Date.now() ||
                  new Date(event.end.dateTime ?? (event.end.date as string)).getTime() > Date.now()
              )
              .sort(
                (a, b) =>
                  new Date(a.start.dateTime ?? (a.start.date as string)).getTime() -
                  new Date(b.start.dateTime ?? (b.start.date as string)).getTime()
              );
          }),
          finalize(() => {
            this.loading = false;
            this.spinnerService.hide();
            this.changeDetectorRef.markForCheck();
          })
        )
        .subscribe((events) => this.refreshCalendarEvents(events));
    } else {
      this.refreshCalendarEvents([]);
    }
  }

  refreshPosts(posts: BlogPost[]) {
    if (this.queryParams.offset === 0) {
      this.posts = [...(posts ?? [])];
    } else {
      this.posts.push(...(posts ?? []));
    }
    this.showLoadButton = !(posts.length === 0 || posts.length < this.limit);
    this.loading = false;
    this.changeDetectorRef.markForCheck();
  }

  loadPosts({ offset }: ChannelQueryParams) {
    this.loading = true;
    this.spinnerService.show({ instant: true });
    this.changeDetectorRef.markForCheck();

    const authorId = this.channel.adminIds?.[0] as string;

    this.blogPostApiService
      .getAll({
        range: { limit: BLOG_POSTS_FEATURE_LIMIT, offset },
        authorId,
        type: 'list'
      })
      .pipe(
        finalize(() => {
          this.loading = false;
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe();
  }

  getChannelInfo(channelName: string) {
    if (!channelName) {
      return of(null);
    }

    return this.videoUploadApiService.getChannel(channelName).pipe(
      takeUntil(this.destroyed$),
      tap((channel) => {
        this.channel = channel;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  loadMedia({ offset }: ChannelQueryParams, showVideo?: boolean) {
    this.loading = true;
    this.spinnerService.show({ instant: true });
    this.changeDetectorRef.markForCheck();

    this.videoUploadApiService
      .getAllForChannel(this.channel.handle as string, { limit: BLOG_POSTS_FEATURE_LIMIT, offset })
      .pipe(
        finalize(() => {
          this.loading = false;
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe((channel) => {
        this.files = channel;
        if (showVideo) {
          this.files.length && this.onPlayVideo(this.files[0]);
        }
      });
  }
  loadDocuments({ offset }: ChannelQueryParams) {
    this.loading = true;
    this.spinnerService.show({ instant: true });
    this.changeDetectorRef.markForCheck();

    this.documentUploadApiService
      .getAll({ range: { limit: BLOG_POSTS_FEATURE_LIMIT, offset }, relatedChannelId: this.channel.id as string })
      .pipe(
        finalize(() => {
          this.loading = false;
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe((channel) => {
        this.documents = channel;
      });
  }

  onEdit() {
    const dialogRef = this.dialogService.open(ChannelEditDialogComponent, {
      data: { channel: this.channel }
    });

    dialogRef
      .afterClosed()
      .pipe(first(Boolean))
      .subscribe((channel) => {
        this.channel = channel as VideoChannelEntity;
        this.changeDetectorRef.markForCheck();
      });
  }

  onEditLinks(additionalLinks?: ParseLinktreeResponse) {
    const channel: VideoChannelEntity = additionalLinks
      ? {
          ...this.channel,
          socialLinks: [...(this.channel.socialLinks ?? []), ...additionalLinks.socialLinks],
          links: [...(this.channel.links ?? []), ...additionalLinks.links]
        }
      : this.channel;
    const dialogRef = this.dialogService.open(ChannelEditLinksDialogComponent, {
      data: { channel }
    });

    dialogRef
      .afterClosed()
      .pipe(first(Boolean))
      .subscribe((channel) => {
        this.channel = channel as VideoChannelEntity;
        this.changeDetectorRef.markForCheck();
      });
  }

  onImportLinks() {
    const dialogRef = this.dialogService.open<ChannelImportLinksDialogComponent, ParseLinktreeResponse>(
      ChannelImportLinksDialogComponent
    );

    dialogRef
      .afterClosed()
      .pipe(first(Boolean))
      .subscribe((links) => {
        this.onEditLinks(links as ParseLinktreeResponse);
      });
  }

  joinChannel() {
    this.spinnerService.show({ instant: true });
    this.videoUploadApiService
      .joinChannel(this.channel.id as string)
      .pipe(
        switchMap(() => this.userProfileApiService.getByUserId(this.authQuery.profile?.userId as string)),
        finalize(() => this.spinnerService.hide())
      )
      .subscribe();
  }

  leaveChannel() {
    this.spinnerService.show({ instant: true });
    this.videoUploadApiService
      .leaveChannel(this.channel.id as string)
      .pipe(
        switchMap(() => this.userProfileApiService.getByUserId(this.authQuery.profile?.userId as string)),
        finalize(() => this.spinnerService.hide())
      )
      .subscribe();
  }

  async onGetOrientation(videoFile: Blob) {
    // eslint-disable-next-line no-prototype-builtins
    if (HTMLVideoElement.prototype.hasOwnProperty('requestVideoFrameCallback')) {
      const video = await this.getVideoElement(videoFile);
      video.muted = true;
      video.playbackRate = 16;
      const { videoWidth, videoHeight } = video;
      video.pause();
      window.URL.revokeObjectURL(video.src);
      video.remove();
      const orientation = videoWidth < videoHeight ? VideoOrientation.Portrait : VideoOrientation.Landscape;
      return { width: videoWidth, height: videoHeight, orientation };
    } else {
      console.error("your browser doesn't support this API yet");
    }
  }
  async onGetDuration(videoFile: Blob) {
    return new Promise<number>((resolve) => {
      // eslint-disable-next-line no-prototype-builtins
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;
        video.remove();
        resolve(duration);
      };

      video.src = URL.createObjectURL(videoFile);
    });
  }

  private async getVideoElement(videoFile: Blob) {
    const video = document.createElement('video');
    video.crossOrigin = 'anonymous';
    video.style.display = 'none';
    video.src = URL.createObjectURL(videoFile);
    document.body.append(video);
    await video.play();
    return video;
  }
}
