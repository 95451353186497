<div class="my-account-sidebar">
  <div class="container">
    <div>
      <ct-sidebar-section class="mb-5">
        <ct-ad-banner-small image='assets/ad-banners/amsoil.png' title='Amsoil' extraActionLink="https://www.amsoil.com/"></ct-ad-banner-small>
      </ct-sidebar-section>
      <ct-sidebar-section *ngIf="(isLoggedIn$ | async) === false" class="mb-5">
        <ct-link link="https://camping.tools/app?ref=app.camping.tools" class="signup-banner">
          <img src="assets/images/sidebar-banner-mobile-app.jpg" alt="Part of Mobile App" loading="lazy" />
        </ct-link>
      </ct-sidebar-section>
      <ct-sidebar-section
        *ngIf="posts"
        class="mb-4"
        [titleKey]="'BLOG.SIDEBAR.RECENT_POSTS'"
        background="white"
      >
        <ng-container *ngIf="posts?.length">
          <ct-blog-post-card
            *ngFor="let post of posts"
            [link]="getLink(post?.id, post?.slug)"
            class="mb-4"
            [post]="post"
            [showLink]="true"
            [showStatus]="false"
            [showAuthor]="false"
            [showTags]="false"
            [linkFullWidth]="true"
            imgHeight="100px"
            imgWidth="100%"
            [imgCols]="12"
            [contentCols]="12"
          ></ct-blog-post-card>
          <ct-button variant="flat" [fullWidth]="true" class="mt-1 mb-1" (click)='onShowStories()'>Show all stories</ct-button>

        </ng-container>
        <ng-container *ngIf="!posts?.length">
          <p class="empty flex-center">{{ 'COMMON.EMPTY' | translate }}</p>
        </ng-container>
      </ct-sidebar-section>
      <ct-sidebar-section class="mb-5">
        <ct-ad-banner-small image='assets/ad-banners/gasstop.jpg' title='GasStop' extraActionLink="https://app.camping.tools/stories/gasstop-the-must-have-propane--5739682946088960"></ct-ad-banner-small>
      </ct-sidebar-section>
    </div>
  </div>
</div>
