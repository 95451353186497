<ct-dialog-content titleKey="Import Links" headerIcon="add-circle" [buttons]="buttons" [alignButtons]="'end'">
  <section class="post">
    <form class="form" [formGroup]="form">
      <div class="row">
        <h4 class="col-12">Linktree URL:</h4>
        <ct-input
          class="col-12"
          [label]="'Linktree URL' | translate"
          [placeholder]="'https://linktr.ee/dmk1111' | translate"
          formControlName="linktree"
        ></ct-input>
        <p>Please, review updated list of links once import finished and confirm or cancel changes</p>
      </div>
    </form>
  </section>
</ct-dialog-content>
