import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortOrder } from '@ct/shared/enums';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { HeaderType } from '../enums';
import { DocumentUpload, DocumentWithUploadUrl, RequestRange } from '../interfaces';
import { BaseHttpService } from '../services';

const endpoint = environment.fileUploadApiBaseUrl;

export const DOCUMENT_MIME_TYPES = [
  'text/csv',
  'text/plain',
  'text/xml',
  'application/x-abiword',
  'application/x-freearc',
  'application/vnd.amazon.ebook',
  'application/octet-stream',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/epub+zip',
  'text/calendar',
  'application/json',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'application/pdf',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/rtf',
  'application/vnd.ms-excel',
  'application/xml'
];

export type CreateDocumentUpload = Pick<
  DocumentUpload,
  'relatedChannelIds' | 'title' | 'description' | 'filename' | 'mimetype' | 'originalname' | 'size'
>;

@Injectable({ providedIn: 'root' })
export class ChannelDocumentUploadApiService extends BaseHttpService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, `${endpoint}/documents`);
  }

  getAll({
    range,
    sortOrder = SortOrder.Desc,
    sortBy,
    authorId,
   relatedChannelId,
  }: {
    range?: RequestRange;
    sortOrder?: SortOrder;
    authorId?: string;
    relatedChannelId: string,
    sortBy?: keyof DocumentUpload;
  }): Observable<DocumentUpload[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (sortBy !== undefined && sortBy !== null) {
      params = params.append('sortBy', sortBy);
    }
    if (authorId !== undefined && authorId !== null) {
      params = params.append('authorId', authorId);
    }

    params = params.append('relatedChannelId', relatedChannelId);
    return this.get(``, params, { headers, withCredentials: true });
  }

  getUploadLink(entity: CreateDocumentUpload) {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    return this.httpClient
      .post<{ data: DocumentWithUploadUrl }>(this.URL + `/upload-link`, entity, { headers })
      .pipe(map(({ data }) => data));
  }

  useUploadLink(file: File, url: string) {
    const headers = {
      [HeaderType.ContentType]: 'application/octet-stream'
    };
    return this.httpClient.put(url, file, { headers });
  }
}
