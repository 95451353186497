<ct-link
  [link]="extraActionLink ?? 'https://www.ritron.com/blog/best-walkie-talkies-for-camping-radio-and-the-great-outdoors'"
  class="ad-banner"
>
  <img
    [src]="image ?? 'assets/ad-banners/ritron_radio.png'"
    [alt]="title ?? 'Ritron Camping Walkie Talkies Ad'"
    loading="lazy"
  />
</ct-link>
