import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpinnerService } from '@ct/components';
import { DialogButton } from '@ct/components/dialog';
import { ChannelVideoUploadApiService, DestroyableFeature, Features, FormStateDispatcher } from '@ct/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'ct-channel-import-links-dialog',
  templateUrl: './channel-import-links-dialog.component.html',
  styleUrls: ['./channel-import-links-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class ChannelImportLinksDialogComponent {
  public readonly destroyed$: Observable<void>;

  public isLoading = false;

  public form = new FormGroup({
    linktree: new FormControl({ value: '', disabled: false }, [Validators.required])
  });

  public get buttons(): DialogButton[] {
    const cancelButton: DialogButton = {
      labelKey: 'COMMON.CANCEL',
      color: 'primary',
      clicked: () => this.dialogRef.close()
    };

    const saveButton: DialogButton = {
      labelKey: 'Import Links',
      clicked: () => {
        this.importLinks();
      },
      color: 'accent'
    };
    return [cancelButton, saveButton];
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    private dialogRef: MatDialogRef<ChannelImportLinksDialogComponent>,
    private formState: FormStateDispatcher,
    private changeDetectorRef: ChangeDetectorRef,
    private spinnerService: SpinnerService,
    private channelVideoUploadApiService: ChannelVideoUploadApiService
  ) {}

  importLinks() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    this.spinnerService.show();
    this.changeDetectorRef.markForCheck();

    const { linktree } = this.form.value;
    return this.channelVideoUploadApiService
      .parseLinktree(linktree as string)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe((response) => {
        this.dialogRef.close(response);
      });
  }
}
