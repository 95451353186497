import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, SelectModule } from '@ct/components';
import { AuthorModule } from '@ct/components/author';
import { CardWrapperModule } from '@ct/components/card-wrapper';
import { DialogModule } from '@ct/components/dialog';
import { IconModule } from '@ct/components/icon';
import { ImageModule } from '@ct/components/image';
import { InputModule } from '@ct/components/input';
import { LinkModule } from '@ct/components/link';
import { TextareaModule } from '@ct/components/textarea';
import { PipesModule } from '@ct/core';
import { TranslateModule } from '@ngx-translate/core';

import { MediaSharedModule } from '../media-shared';
import {
  ChannelEditDialogComponent,
  ChannelEditLinksDialogComponent,
  ChannelImportLinksDialogComponent
} from './components';

@NgModule({
  declarations: [ChannelEditDialogComponent, ChannelEditLinksDialogComponent, ChannelImportLinksDialogComponent],
  imports: [
    CommonModule,
    AuthorModule,
    PipesModule,
    LinkModule,
    TranslateModule,
    ImageModule,
    IconModule,
    CardWrapperModule,
    MediaSharedModule,
    DialogModule,
    ReactiveFormsModule,
    InputModule,
    TextareaModule,
    SelectModule,
    ButtonModule
  ],
  exports: [ChannelEditDialogComponent, ChannelEditLinksDialogComponent, ChannelImportLinksDialogComponent]
})
export class VideoChannelSharedModule {}
