<ct-dialog-content titleKey="My Channel" headerIcon="add-circle" [buttons]="buttons" [alignButtons]="'end'">
  <section class="post">
    <form class="form" [formGroup]="form">
      <div formArrayName="links">
        <div class="row">
          <h4>External Links:</h4>
        </div>
        <ng-container *ngFor="let control of linksControl.controls; let i = index; let first = first">
          <div class="row mb-3" [formGroupName]="i">
            <div class="col-11">
              <div class="row no-gutters">
                <ct-input
                  class="col-12"
                  [label]="'Link Label' | translate"
                  [placeholder]="'Enter display label of the URL' | translate"
                  formControlName="label"
                ></ct-input>
                <ct-input
                  class="col-12"
                  [label]="'URL' | translate"
                  [placeholder]="'https://app.camping.tools/' | translate"
                  formControlName="url"
                ></ct-input>
              </div>
            </div>
            <div class="col-1 px-0 d-flex">
              <div class="row no-gutters align-content-center">
                <ct-icon icon="delete" color="warn" class="col-12 px-0 text-center" (click)="onDeleteLink(i)"></ct-icon>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="row">
          <div class="col-11">
            <ct-button
              class="padding-bottom-3"
              (click)="onAddLink()"
              [disabled]="linksControl.invalid"
              [fullWidth]="true"
              >Add Another Link</ct-button
            >
          </div>
        </div>
      </div>
      <div formArrayName="socialLinks">
        <div class="row">
          <h4>Social Links:</h4>
        </div>
        <ng-container *ngFor="let control of socialLinksControl.controls; let i = index; let first = first">
          <div class="row mb-3" [formGroupName]="i">
            <div class="col-11">
              <div class="row no-gutters">
                <ct-input
                  class="col-12"
                  [label]="'Social network name' | translate"
                  [placeholder]="'Enter name of the social network link points to' | translate"
                  formControlName="label"
                ></ct-input>
                <ct-input
                  class="col-12"
                  [label]="'URL' | translate"
                  [placeholder]="'https://google.com/' | translate"
                  formControlName="url"
                ></ct-input>
              </div>
            </div>
            <div class="col-1 px-0 d-flex">
              <div class="row no-gutters align-content-center">
                <ct-icon
                  icon="delete"
                  color="warn"
                  class="col-12 px-0 text-center"
                  (click)="onDeleteSocialLink(i)"
                ></ct-icon>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="row">
          <div class="col-11">
            <ct-button
              class="padding-bottom-3"
              (click)="onAddSocialLink()"
              [disabled]="socialLinksControl.invalid"
              [fullWidth]="true"
              >Add One More Social Link</ct-button
            >
          </div>
        </div>
      </div>
    </form>
  </section>
</ct-dialog-content>
