<div class="chat-header">
  <span>AI Assistant</span>
  <ct-icon class="chat-header__close" (click)="closeChat()" icon="close"></ct-icon>
</div>

<div class="chat-messages" #chatContainer>
  <div
    class="message-wrapper"
    [ngClass]="{
      'message-wrapper--user': msg.sender === ChatMessageSender.User,
      'message-wrapper--assistant': msg.sender === ChatMessageSender.Assistant
    }"
    *ngFor="let msg of chatService.messages"
  >
    <ct-user-avatar
      class="author__photo"
      [src]="msg.sender === ChatMessageSender.User ? (loggedInUser$ | async)?.photo?.publicUrl : undefined"
      [icon]="msg.sender === ChatMessageSender.User ? undefined : 'robot'"
    ></ct-user-avatar>
    <div
      class="message"
      [ngClass]="{ user: msg.sender === ChatMessageSender.User, assistant: msg.sender === ChatMessageSender.Assistant }"
    >
      <p *ngIf="msg.text" class="message-text" [innerHTML]="msg.text | safe:'html'"></p>

      <!-- If it's a buttons-type message and buttons are still shown -->
      <div class="message-buttons" *ngIf="msg.type === 'buttons' && msg.showButtons">
        <ct-button *ngFor="let btn of msg.buttons" color="primary" (click)="onButtonClick(msg, btn)">
          {{ btn.label }}
        </ct-button>
      </div>

      <!-- If it's an items-type message and items are still shown -->
      <div class="message-items" *ngIf="msg.type === 'items' && msg.showItems">
        <div class="item" *ngFor="let item of msg.items; let i = index">
          <p class="item-name">
            <strong>{{ i + 1 }}. {{ item.name }}</strong
            ><span *ngIf="item.description">: {{ item.description }}</span>
          </p>
        </div>
        <ct-button variant="stroked" color="primary" (click)="onAddItems(msg)"> Add all to trip </ct-button>
      </div>

      <div class="message-timestamp">
        {{ msg.timestamp | date : 'shortTime' }}
      </div>
    </div>
  </div>
</div>

<div class="chat-input">
  <ct-input label="Type a message..." [formControl]="messageControl" (keyup.enter)="sendMessage()" />
  <ct-button color="primary" (click)="sendMessage()">
    <ct-icon icon="send"></ct-icon>
  </ct-button>
</div>
